export class PaginationModel {
    constructor(sortBy, descending, page, rowsPerPage, rowsNumber) {
        this.sortBy = sortBy;
        this.descending = descending;
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        this.rowsNumber = rowsNumber;
    }
    sortBy;
    descending;
    page;
    rowsPerPage;
    rowsNumber;
}
